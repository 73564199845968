import React from "react"


const NotFoundPage = () => (
  <div>
    <h1>404 NOT FOUND</h1>
    <p>This page hasn't been built yet! Just come back in a few days, and maybe it'll be here.</p>
  </div>
)

export default NotFoundPage
